import React from "react"
import Layout from "../components/Layout" 
import { graphql, Link } from "gatsby"
import img from "../images/creations/leroiestmort_main.jpg"
import img2 from "../images/creations/leroiestmort_crea.jpg"
import img3 from "../images/creations/sky_crea.jpg"

const CreationsPage = ({ data }) => {

  return (
    <>
      <Layout>

        <div className="page-wrapper creations">
          <div className="main-image">
            <img src={img}/>
          </div>

          <h1>EN CRÉATION</h1>
          
          <div className="list-crea">
            <Link className="list-crea-item" to="/creations/sky">
              <img src={img3} />
              <h2>Sky</h2>
              <h3>2024</h3>
              <div className="description">
                <p>"Un ciel robotique, des animaux humains..."</p>
              </div>
            </Link>
            
            <Link className="list-crea-item" to="/creations/leroiestmort">
              <img src={img2} />
              <h2>Le roi est mort</h2>
              <h3>2023</h3>
              <div className="description">
                <p>"L'ia au coeur de la création artistique la plus humaine"</p>
              </div>
            </Link>
          </div>
          
          <Link className="button" to="/contact">Contact</Link>
      </div>
      </Layout>
    </>
  )
}

export default CreationsPage

//export const query = graphql`
//  {
//    BlogPostQuery: allMarkdownRemark(
//      sort: { fields: [frontmatter___date], order: DESC }
//      limit: 3
//    ) {
//      totalCount
//      edges {
//        node {
//          frontmatter {
//            title
//            date
//            path
//            featuredImage {
//              childImageSharp {
//                gatsbyImageData(
//                  layout: FULL_WIDTH
//                  placeholder: TRACED_SVG
//                  formats: [AUTO, WEBP]
//                )
//              }
//            }
//            featuredImageAlt
//          }
//          excerpt
//        }
//      }
//    }
//  }
//`
